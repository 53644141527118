import './App.css';
import Banner from './components/Banner';
import Hero from './components/sections/Hero';
import About from './components/sections/About';
import Experience from './components/sections/Experience';
import Projects from './components/sections/Projects';
import Tools from './components/sections/Tools';
import Contact from './components/sections/Contact';
import Footer from './components/sections/Footer';

function App() {
  return (
    <>
        <Banner />
        <main>
            <Hero />
            <About />
            <Tools />
            <Experience />
            <Projects />
            <Contact />
        </main>
        <Footer />
    </>
  );
}

export default App;
