import './Tools.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHtml5 } from '@fortawesome/free-brands-svg-icons';
import { faCss3 } from '@fortawesome/free-brands-svg-icons';
import { faSass } from '@fortawesome/free-brands-svg-icons';
import { faBootstrap } from '@fortawesome/free-brands-svg-icons';
import { faJs } from '@fortawesome/free-brands-svg-icons';
import { faReact } from '@fortawesome/free-brands-svg-icons';
import { faNodeJs } from '@fortawesome/free-brands-svg-icons';
import { faGitAlt } from '@fortawesome/free-brands-svg-icons';
import { faFigma } from '@fortawesome/free-brands-svg-icons';
import { faPython } from '@fortawesome/free-brands-svg-icons';
import { faWheelchairMove } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

function Tools() {
    return (
        <section className='tools'>
            <hgroup>
                <h2>My Stack</h2>
                <p>Technologies I'm Most Comfortable With</p>
            </hgroup>

            <div className='flex-wrapper'>
                <span className='tools-span' ><FontAwesomeIcon icon={faHtml5} className='tool-icon' />HTML5</span>
                <span className='tools-span' ><FontAwesomeIcon icon={faCss3} className='tool-icon' />CSS3</span>
                <span className='tools-span' ><FontAwesomeIcon icon={faJs} className='tool-icon' />JavaScript</span>
                <span className='tools-span' ><FontAwesomeIcon icon={faSass} className='tool-icon' />SASS</span>
                <span className='tools-span' ><FontAwesomeIcon icon={faBootstrap} className='tool-icon' />Bootstrap</span>
                <span className='tools-span' ><FontAwesomeIcon icon={faReact} className='tool-icon' />React</span>
                <span className='tools-span' ><FontAwesomeIcon icon={faNodeJs} className='tool-icon' />Node.js</span>
                <span className='tools-span' ><FontAwesomeIcon icon={faGitAlt} className='tool-icon' />Git</span>
                <span className='tools-span' ><FontAwesomeIcon icon={faFigma} className='tool-icon' />Figma</span>
            </div>

            <p>Currently Working On</p>
            <div className='flex-wrapper'>
                <span className='tools-span' ><FontAwesomeIcon icon={faPython} className='tool-icon' />Python</span>
                <span className='tools-span' ><FontAwesomeIcon icon={faWheelchairMove} className='tool-icon' />Accessibility <br /> Principles</span>
                <span className='tools-span' ><FontAwesomeIcon icon={faPlus} className='tool-icon' />And More!</span>
            </div>
        </section>
    );
}

export default Tools;