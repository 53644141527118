import './About.css';
import headshot from '../pics/headshot.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';

function About() {

    // Trying to figure out FileSaver.js!
    // Though I think I may be using the wrong
    const FileSaver = require('file-saver');

    return (
        <>
            <section className='about' id='about'>
                <img src={headshot} alt="Me this past Easter, stuck in my parents house grading exams" />

                <hgroup>
                    <h2>My name is Michael.</h2>
                    <p>I apprciate you reading this.
                        I'm currently an IT Consultant, Independent Web Developer, and Masters Student.</p>
                    <br />
                    <a href='https://github.com/michaellegere' >
                        <FontAwesomeIcon icon={faGithub} className='social-icon' />
                    </a>
                    <a href='https://www.linkedin.com/in/michael-legere/' >
                        <FontAwesomeIcon icon={faLinkedin} className='social-icon' />
                    </a>
                    <a href='https://twitter.com/michaellegere_' >
                        <FontAwesomeIcon icon={faTwitter} className='social-icon' />
                    </a>
                </hgroup>

            </section>

            <button onClick={
                function saveResume() {
                    FileSaver.saveAs('https://github.com/michaellegere/downloadables/blob/main/Website-Resume.pdf');
                }
            }>
            Check Out + Download my Resume on GitHub
            </button>

        </>
    );
  }

export default About;