import './PrimaryButton.css'

const PrimaryButton = props => {

    return <a className='primary-link' href={props.url}> <input className='primary-button'
        type='button'
        value={props.text}
    /> </a>
}

export default PrimaryButton;