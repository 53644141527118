import './Hero.css';
import ArrowDownward from '@mui/icons-material/ArrowDownward';

function Hero() {
    return (
      <section className='hero' id='hero'>

        <div className='box' >
          <h1>Welcome to my Website.</h1>
          <p>Philosopher, Educator, Web Developer.</p>
        </div>

        <div className="scroll-down">
            <a href="#about">
                <ArrowDownward className='arrow'></ArrowDownward>
                <p>scroll down</p> 
            </a>
        </div>
      </section>
    );
  }

export default Hero;