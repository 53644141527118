import './Projects.css';
import ProjectCard from '../cards/ProjectCard';

function Projects() {
    return (
        <section className='projects' id='projects'>
            <hgroup>
                <h2>My Projects</h2>
                <p>What I've been Working On</p>
            </hgroup>

            <div className='flex-wrapper'>
            <ProjectCard
                    title='#100DaysOfCode'
                    description="I'm currently taking the #100DaysOfCode challenge to build more and show off what
                    I've learned. Feel free to check out my progress!"
                    live="#"
                    repo='https://github.com/michaellegere/100-days-of-code'
                />
                <ProjectCard
                    title='pulltarot.cards'
                    description='Vanilla JS App that allows users to pull 3 Tarot cards and prompts
                                user to reflect on what those cards might mean to them.'
                    live='https://pulltarot.cards'
                    repo='https://github.com/michaellegere/tarot-app'
                />
            </div>
        </section>
    );
}

export default Projects;