import './Footer.css';

function Footer() {
    return (
      <footer>
        <h5>Copyright Michael Legere 2022.</h5>
      </footer>
    );
  }

export default Footer;