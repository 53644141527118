import './ProjectCard.css';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';

function ProjectCard(props) {
    return (
        <div className='project-card'>

            <h3>{props.title}</h3>

            <p>{props.description}</p>

            <div className='flex-wrapper'>
                <PrimaryButton text='View Live Demo' url={props.live} />
                <SecondaryButton text='View Repo' url={props.repo} />
            </div>

        </div>
    );
}

export default ProjectCard;