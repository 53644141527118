import './Experience.css';
import ExpCard from '../cards/ExpCard';
import School from '@mui/icons-material/School';
import Work from '@mui/icons-material/Work';

function Experience() {
    return (
      <section className='experience' id='experience'>
        <hgroup>
            <h2>My Experience</h2>
            <p>What I've been up to</p>
        </hgroup>

        <div className='flex-wrapper'>
                <ExpCard
                    title='Education'
                    icon={<School />}
                    roleOne='Boston College, M.A. Philosophy'
                    dateOne='Aug 2021 - May 2023'
                    roleTwo='Emmanuel College, B.A. Philosophy'
                    dateTwo='Sept 2017 - May 2020'
                />
                <ExpCard
                    title='Work Highlights'
                    icon={<Work />}
                    roleOne='Boston College Information Technology'
                    dateOne='Jan 2022 - Present'
                    roleTwo='Museum of Science, Boston'
                    dateTwo='March 2015 - Apr 2020'
                />
        </div>
      </section>
    );
  }

export default Experience;