import './Banner.css';
import Home from '@mui/icons-material/Home';
import Person from '@mui/icons-material/Person';
import History from '@mui/icons-material/History';
import Code from '@mui/icons-material/Code';
import Send from '@mui/icons-material/Send';


function Banner() {
    return (
        <>
            <nav className='banner'>
                <h3>Michael Legere</h3>

                <ul>
                    <li>
                        <a href='#hero'><Home></Home> &nbsp;&nbsp; home</a>
                    </li>
                    <li>
                        <a href='#about'><Person></Person> &nbsp;&nbsp; about</a>
                    </li>
                    <li>
                        <a href='#experience'><History></History> &nbsp;&nbsp; experience</a>
                    </li>
                    <li>
                        <a href='#projects'><Code></Code> &nbsp;&nbsp; projects</a>
                    </li>
                    <li>
                        <a href='#contact'><Send></Send> &nbsp;&nbsp; contact</a>
                    </li>
                </ul>
            </nav>

            <nav className='banner-mobile'>
                <ul>
                    <li>
                        <a href='#hero'><Home></Home></a>
                    </li>
                    <li>
                        <a href='#about'><Person></Person></a>
                    </li>
                    <li>
                        <a href='#experience'><History></History></a>
                    </li>
                    <li>
                        <a href='#projects'><Code></Code></a>
                    </li>
                    <li>
                        <a href='#contact'><Send></Send></a>
                    </li>
                </ul>
            </nav>
        </>

    );
  }

export default Banner;