import './Contact.css';
import emailjs from '@emailjs/browser';

function Contact() {

    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('gmail', 'template_pt3op2p', e.target, 'MeAYeNMVKERdiqpWJ')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset()
    };

    return (
        <section className='contact' id='contact'>
            <hgroup>
                <h2>Contact Me</h2>
                <p>Feel free to say 'Howdy!'</p>
            </hgroup>

            <form onSubmit={sendEmail}>

                <input type='text' id='name' name='name' placeholder='Your Name'/>
                <input type='email' id='email' name='email' placeholder='Email'/>
                <input type='text' id='subject' name='subject' placeholder='Subject'/>
                <br />
                <textarea id='message' name='message' placeholder='Message' />
                <br />
                <button type='submit' className='submit-button' >Send Message</button>

            </form>

            <p className='alt-contact'>
                Don't like contact forms? (Honestly, me neither.)
                You can also feel free to
                <a href='mailto:mlegere18@gmail.com'> shoot me an email</a>!
            </p>

        </section>
    );

}

export default Contact;